// THIS FILE IS AUTO-GENERATED. DO NOT EDIT THIS FILE DIRECTLY.
// EDIT THE "locales.ts" FILE IN THE ROOT INSTEAD. 
export const sourceLocale = 'en';
export const locales = {
    en: 'English',
    es: 'Español',
    ru: 'Русский',
    ja: 'やまと',
    ko: '한국어',
    ar: 'عربي',
};
